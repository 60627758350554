<template>
  <div class="relation-item" :class="classes" @mouseover="setOverState" @mouseout="setOutState" @click="setSelectedState">
    <div class="cols">
      <template v-if="bestEvent">
        <img :key="'cluster-image'" :src="bestEvent.thumbnail" class="cluster-image-thumbnail" />
      </template>
      <div class="mar-h-1 rows">
        <div v-if="card" @click.stop>
          <a :href="getCardUrl(card)" class="text-xl episode-link" target="_blank">{{ $filters.shortString(card.name, 16) }}</a>
        </div>
        <div class="text--large" v-else>{{ $tfo('unknown_cluster') }}</div>

        <div @click.stop>
          <router-link :to="`/cluster-events/faces/filter/cluster_in=${item.id}&limit=10`" class="item-id" target="_blank">{{ item.id }}</router-link>
        </div>

        <div class="flex-cell"></div>

        <div class="watch-lists-container" v-if="item.matched_lists">
          <span
            class="card-list-item text-s"
            :style="{ backgroundColor: '#' + list.color, color: getFontColor(list) }"
            v-for="list in getWatchlists(item.matched_lists)"
          >
            {{ list.name | shortString }}
          </span>
        </div>
      </div>
    </div>
    <div></div>
  </div>
</template>

<script>
import Color from 'color';
import { humanCardsModule } from '../../store';

export default {
  name: 'relation-item',
  props: {
    item: {
      type: Object,
      required: true
    },
    currentItemState: {
      type: Object
    }
  },
  data: function () {
    return {
      card: null,
      containerState: {
        over: false
      }
    };
  },
  computed: {
    bestEvent() {
      return this.item.best_event;
    },
    isSelected() {
      return this.currentItemState && this.currentItemState.current && this.currentItemState.current.id === this.item.id;
    },
    classes() {
      return {
        'relation-item--selected': this.isSelected,
        'relation-item--over': this.containerState.over
      };
    }
  },
  watch: {
    item: function (v, p) {
      this.card = null;
      if (this.item.card > 0) this.loadCard();
    }
  },
  mounted() {
    if (this.item.card > 0) this.loadCard();
  },
  methods: {
    setOverState() {
      if (this.isSelected) return;
      this.containerState.over = true;
    },
    setOutState() {
      this.containerState.over = false;
    },
    setSelectedState() {
      this.currentItemState && (this.currentItemState.current = this.item);
      this.setOutState();
    },
    setUnselectedState() {
      this.currentItemState && (this.currentItemState.current = null);
    },
    toggleSelectedState() {
      this.isSelected ? this.setUnselectedState() : this.setSelectedState();
    },
    getFontColor(item) {
      let color = Color('#' + item.color),
        isLight = color.light();
      return isLight ? '#000' : 'rgba(255, 255, 255, 0.8)';
    },
    getWatchlists(v) {
      const lists = v.map((id) => this.$store.state.watch_lists.items.find((v) => v.id === id));
      return lists;
    },
    getCardUrl(v) {
      return '#/cards/humans/' + v.id;
    },
    cardClickHandler(v) {
      this.$router.push({ path: '/cards/' + v.id });
    },
    loadCard() {
      humanCardsModule.getById({ id: this.item.card }).then((v) => {
        this.card = v;
      });
    }
  }
};
</script>

<style lang="stylus">
.relation-item {
  overflow: hidden;
  width: 21rem;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.2s, transform 0.3s 0.1s;
  transform: translateX(0px);

  .text--large {
    display: block;
    color: rgba(255, 255, 255, 0.1);
    font-weight: 900;
    font-size: 30px;
    line-height: 0.9;
    letter-spacing: -3px;
    border-radius: 4px;
    text-transform: uppercase;
  }

  .item-acknowledge-icon {
    display: none;
    position: absolute;
    right: 0;
    top: 0;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #1478f6;
  }

  .item-id {
    margin-top: 0.5rem;
    font-size: 0.75rem;
  }

  .item-header {
    position: relative;
  }

  .item-name {
    margin-bottom: 0.5rem;
    padding-right: 20px;
  }

  .cluster-image-thumbnail {
    position: relative;
    object-fit: cover;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: rgba(0, 0, 0, 0.1);
    min-width: 120px;
    height: 120px;
    top: 0;
    border-radius: 4px;
    transition: border 0.5s;
  }

  .watch-lists-container {
    margin-top: 1rem;
  }

  .card-list-item {
    display: inline-block;
    margin-right: 0.5rem;
    background-color: gray;
    color: white;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    border-radius: 4px;
  }

  &--selected {
    transform: translateX(0.5rem);
    cursor: default;
    background-color: #1478f6;
  }

  &--over {
    background-color: rgba(255, 255, 255, 0.2);
  }

  &--acknowledged {
    .item-acknowledge-icon {
      display: block;
    }
  }
}
</style>
