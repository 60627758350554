<template>
  <div class="cols h--100 clear-padding">
    <div class="relation-items" v-loading="clustersState.loading" v-infinite-scroll="() => append(-1)" infinite-scroll-delay="1000">
      <div class="content-header mar-left--1">
        <h2>{{ $tf('interaction_analysis') }}</h2>
      </div>

      <h3 class="text-center" v-if="clustersState.loading">{{ $tf('loading') }}</h3>
      <h3 class="text-center" v-else-if="!clustersState.loading && !clustersState.items.length">{{ $tf('no_data') }}</h3>

      <relation-item :key="'key-' + item.id" :item="item" :current-item-state="level0" v-for="item in clustersState.items" />
      <h3 v-if="clustersState.appending" class="text-center">{{ $tf('loading') + '...' }}</h3>
    </div>

    <template v-for="(level, index) in currentLevels">
      <div class="relation-items cluster-events" :class="{ open: isLevelOpen(index) }">
        <template v-if="isLevelOpen(index)">
          <div class="content-header">
            <h2>{{ $tfo('events') }}</h2>
          </div>
          <cluster-events-item
            :key="'key-' + items.join('-')"
            :items="items"
            :objects-type="objects"
            :use-last-event="state.levels[index + 1].filter.current.use_last_event"
            v-for="items in state.levels[index + 1].current.matched_cluster_events"
          />
        </template>
      </div>

      <div class="relation-items" v-loading="level.loading" v-infinite-scroll="() => append(index)">
        <div class="content-header">
          <h2>{{ $tfo('circle') + ' ' + (index + 1) }}</h2>
        </div>
        <h3 class="text-center" v-if="level.loading">{{ $tf('loading') }}</h3>
        <h3 class="text-center" v-else-if="!level.loading && !level.items.length">{{ $tf('no_data') }}</h3>

        <relation-item :key="'relation-' + item.id" :item="item" :current-item-state="state.levels[index + 1]" v-for="item in level.items" />
        <h3 v-if="level.appending" class="text-center">{{ $tf('loading') + '...' }}</h3>
      </div>
    </template>
  </div>
</template>

<script>
import RelationItem from './relation.item';
import ClusterEventsItem from './cluster.events.item';
import { faceClusterListModule } from '../../store';
import { ObjectsType } from '../../store/objects/get.module';

export default {
  name: 'contacts',
  components: { ClusterEventsItem, RelationItem },
  computed: {
    objects() {
      return ObjectsType.Faces;
    },
    level0() {
      return this.state.levels[0];
    },
    level1() {
      return this.state.levels[1];
    },
    level2() {
      return this.state.levels[2];
    },
    currentLevels() {
      return this.state.levels.slice(0, this.state.levels.length - 1).filter((v) => v.current);
    },
    state() {
      return this.$store.state.face_contacts;
    },
    clustersState() {
      return faceClusterListModule;
    }
  },
  watch: {
    'level0.current': function (v, p) {
      this.loadLevel(v, 0);
    },
    'level1.current': function (v, p) {
      this.loadLevel(v, 1);
    },
    'level2.current': function (v, p) {
      this.loadLevel(v, 2);
    },
    '$route.params.filter': function (v, p) {
      this.$store.dispatch('getFacesContacts', { id: null, level: -1 });
    }
  },
  mounted() {
    this.loadLevel({ id: null }, -1);
  },
  methods: {
    append(levelIndex) {
      if (levelIndex === -1) {
        if (this.clustersState.filter.current.id) {
          return;
        } else {
          faceClusterListModule.append();
        }
      } else {
        this.$store.dispatch('getFacesContacts', {
          id: this.$store.state.face_contacts.levels[levelIndex].current.id,
          level: levelIndex,
          append: true
        });
      }
    },
    loadLevel(v, level) {
      if (v) {
        this.$store.dispatch('getFacesContacts', { id: v.id, level });
      } else {
        this.state.levels[level].items = [];
      }
    },
    isLevelOpen(index) {
      return !!this.state.levels[index + 1].current;
    }
  }
};
</script>
